import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
const item1 = {image: "/img/Chen-Sikai.jpg"}
const item2 = {image: "/img/captain_award.jpg"}
const item3 = {image: "/img/course1.png"}
const item4 = {image: "/img/SKY-editor.png"}
const item5 = {image: "/img/TRB2021.png"}
const item6 = {image: "/img/group_sue.png"}
const item7 = {image: "/img/graduation-1.png"}
const item8 = {image: "/img/elevator1.png"}
const item9 = {image: "/img/informs2020.jpg"}
const item10 = {image: "/img/group_paul.png"}
const item11 = {image: "/img/course2.png"}
const item12 = {image: "/img/aboutme.png"}
const item13 = {image: "/img/computer-aided.png"}
const item14 = {image: "/img/PartC.png"}
const item15 = {image: "/img/INFORM2021.png"}
const item16 = {image: "/img/IEEE.png"}

export const IndexPageTemplate = ({
  image,
  title,
  
}) => (
  <div>
    
    

    <section className="section section--gradient" style = {{padding:'0rem'}}>
      <div className="container">
      Thank you for your interest.
      <p>
      We have moved to:
      </p>
      <Link to="https://sky-lab-uw.github.io"> sky-lab-uw.github.io</Link>
      
      </div>
    </section>
    
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
